:root {
  /* Prefix app-level variables to prevent conflicts */
  --app-primary-bg: #222;
  --app-secondary-bg: #333;
  --app-text-color: #fff;
  --app-button-bg: #063e5d;
  --app-button-hover-bg: #0a5c8a;
  --app-input-bg: #2a2a2a;
  --app-input-border: #444;
  --app-input-focus-border: #50fdfd;
}

/* Reset these properties at the app level */
#root {
  background-color: var(--app-primary-bg);
  color: var(--app-text-color);
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--app-primary-bg);
  color: var(--app-text-color);
}

.main-content {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--app-primary-bg);
}

.app-header {
  background-color: var(--app-secondary-bg);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.logo {
  height: 50px;
}

.main-menu {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.menu-button,
.large-menu-button {
  background-color: var(--app-button-bg);
  color: var(--app-text-color);
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s;
}

.menu-button:hover,
.large-menu-button:hover {
  background-color: var(--app-button-hover-bg);
}

.large-menu-button {
  font-size: 18px;
  padding: 15px 20px;
}

.full-width-page {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--app-primary-bg);
}

/* Reset for page transitions */
.page-container {
  background-color: var(--app-primary-bg);
  color: var(--app-text-color);
}

@media (max-width: 768px) {
  .main-menu {
    flex-direction: column;
  }

  .menu-button,
  .large-menu-button {
    width: 100%;
  }
}