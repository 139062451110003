:root {
    --primary-bg: #222;
    --secondary-bg: #333;
    --text-color: #fff;
    --button-bg: #063e5d;
    --button-hover-bg: #0a5c8a;
}

.app-header {
    background-color: var(--secondary-bg);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    color: var(--text-color);
}

.main-menu {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.menu-button,
.large-menu-button {
    background-color: var(--button-bg);
    color: var(--text-color);
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    transition: background-color 0.3s;
}

.menu-button:hover,
.large-menu-button:hover {
    background-color: var(--button-hover-bg);
}

.large-menu-button {
    font-size: 18px;
    padding: 15px 20px;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

@media (max-width: 768px) {
    .main-menu {
        flex-direction: column;
    }

    .menu-button,
    .large-menu-button {
        width: 100%;
    }
}
